<template>
  <div class="Upgrade">
      <div class="header">
        <div class="header_img">
           <img style="width: 120px;height: 30px;vertical-align: middle;" src="../assets/img/Frame_logo.png">  <span class="neic">内测版</span>
        </div>
        <div class="header_test" @click="logColic()">登录/注册</div>
      </div>
      <div class="chentn">
         <div class="chentn_lent">
             <div class="xiaolu">小鹿云剪</div>
             <div class="xiaolu1">从灵感到爆款</div>
             <div class="xiaolu2">只要1分钟</div>
             <div class="xiaolu3">人人可用的短视频创作工具</div>
             <div class="xiaolu4" @click="logColic()">立即创作</div>
         </div>
         <div class="chentn_rignt">
            <img style="width: 700px;" src="../assets/img/Group_da1.png">
         </div>
      </div>
      <div class="dibu"><span style="cursor: pointer;" @click="YeColick1">粤ICP备2022047578号-3</span> ©2023 xiaolu178.video版权所有 联系电话：075523768135 公司地址：深圳市龙华区大浪街道龙胜恒博中心1002</div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  methods:{
    logColic(){
      window.location.href = 'https://www.xiaolu178.com';
    },
    YeColick1(){
        window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
      },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
.dibu{
  color: rgba(0, 0, 0, 0.40);
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 400;
  text-align: center;
  position: fixed;
  width: 100%;
  bottom: 20px;
}
.xiaolu1{
  font-size: 79px;
  color: transparent;
  background-image: linear-gradient(190deg, #010308 0%, #3B8DFC 100%);
  background-clip: text;
    font-weight: 900;
    letter-spacing: 4px;
    margin-top: 22px;
    margin-bottom: 8px;
}
.xiaolu2{
  font-size: 79px;
  font-weight: 400;
  font-family: PingFang SC,sans-serif;
  color: #3B8DFC ;
  letter-spacing: 4px;

}
.xiaolu3{
  font-size: 34px;
  font-weight: 600;
  color: transparent;
  background-image: linear-gradient(190deg, #010308 0%, #3B8DFC 100%);
  background-clip: text;
  margin-top: 20px;
  margin-bottom: 39px;
}
.xiaolu4{
  width: 200px;
  height: 62px;
  background: linear-gradient(96deg, #D5ADFB 0%, #5F82F5 34%, #5483F4 51%, #4299F1 75%, #30F5F5 100%);
  border-radius: 30px;
  font-size: 22px;
  color: #fff;
  text-align: center;
  line-height: 62px;
  cursor: pointer;
}
.neic{
  background: #F0F6FF;
  color: #3C7CFC;
  font-size: 12px;
  padding: 6px;
  border-radius: 10px;
}
.header_img{

}
.xiaolu{
    color: #4783FC;
    border-radius: 10px;
    /* padding: 5px 10px; */
    border: 2px solid #4783FC;
    width: 140px;
    height: 35px;
    font-weight: 600;
    font-size: 26px;
    text-align: center;
}
.chentn{
  width: 1200px;
  height: 500px;
  margin: 0 auto;
  display: flex;
  margin-top: 80px;
}
.header_test{
  width: 104px;
  height: 48px;
  background: #467ef2;
  font-weight: 500;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 48px;
  border-radius: 30px;
  cursor: pointer;
}
.Upgrade {
  background: #f7f7f7;
  height: 100vh;
  background-image: url('../assets/img/Group_bei1.png');
  background-size: 100% 100%;
}
.header{
  width: 95%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}
</style>
